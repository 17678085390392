import React from "react";

// Mui
import { withStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// App
import theme from "app/theme";

export const MaxWidthContainer = styled(Grid)({
  maxWidth: "1400px",
  padding: "0px 30px",
  margin: "0 auto",
});

export const HeroGrid = styled(Grid)({
  backgroundImage: "url(./hero.png)",
  backgroundSize: "cover",
  width: "100vw",
  backgroundPosition: "10% 100%",
  height: "30vh",
  [theme.breakpoints.up("sm")]: {
    height: "370px",
  },
  [theme.breakpoints.up("md")]: {
    height: "410px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "450px",
    backgroundPosition: "10% 50%",
  },
});

const styles = (theme) => ({
  logo: {
    height: "150px",
    marginTop: "-24px",
    [theme.breakpoints.up("sm")]: {
      height: "170px",
      marginTop: "-14px",
      marginBottom: "8px",
    },
    [theme.breakpoints.up("md")]: {
      height: "185px",
      marginTop: "-20px",
      marginBottom: "8px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
      marginTop: "-34px",
      marginBottom: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "220px",
      marginTop: "-38px",
      marginBottom: "18x",
    },
  },
  whiteText: {
    color: "#fff",
  },
  mainTitle: {
    textTransform: "uppercase",
    fontSize: "20px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "44px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "50px !important",
    },
  },
  h1MarginTop: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "28px !important",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px !important",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "35px !important",
    },
  },
  supportingTitle: {
    fontSize: "15px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "26px !important",
    },
  },
  committee: {
    backgroundColor: theme.palette.grey.veryLight,
    padding: "12px 0px",
  },
});

const PageTemplate = ({
  classes,
  mainTitle,
  supportingTitle1,
  supportingTitle2,
  children,
}) => {
  return (
    <>
      <HeroGrid>
        <MaxWidthContainer>
          <img
            src="./PastorsMastersLogo.svg"
            alt="Pastors Masters logo"
            className={classes.logo}
          />

          <Typography
            variant="h3"
            className={`${classes.whiteText} ${classes.supportingTitle}`}
          >
            {supportingTitle1}
          </Typography>
          <Typography
            variant="h1"
            className={`${classes.whiteText} ${classes.mainTitle} ${
              !supportingTitle1 ? classes.h1MarginTop : ""
            }`}
          >
            {mainTitle}
          </Typography>
          {supportingTitle2 && (
            <Typography
              variant="h3"
              className={`${classes.whiteText} ${classes.supportingTitle}`}
            >
              {supportingTitle2}
            </Typography>
          )}
        </MaxWidthContainer>
      </HeroGrid>

      {children}
    </>
  );
};

export default withStyles(styles)(PageTemplate);
