/* React core */
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

// Mui
import { ThemeProvider } from "@mui/material/styles";

// App
import "./style.css";
import Nav from "nav/nav";
import Home from "pages/home";
import Registration from "pages/registration";
import Sponsor from "pages/sponsor";
// import Error from "pages/error";
import theme from "app/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Nav />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/sponsorships" element={<Sponsor />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
