import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Mui
import { withStyles, useTheme } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";

// App
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";

import { MaxWidthContainer } from "components/pageTemplate";

const styles = (theme) => {
  return {
    nav: {
      padding: "16px 0px",
      textAlign: "right",
    },
    link: {
      letterSpacing: 0.5,
      fontWeight: 600,
      fontSize: "0.75rem",
      textDecoration: "none",
      marginLeft: "20px",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.875rem",
        marginLeft: "30px",
      },
    },
    inactiveLink: {
      color: theme.palette.grey.dark,
    },
    activeDrawerLink: {
      fontWeight: 700,
      borderLeft: `6px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      paddingLeft: "24px !important",
    },
    activeLink: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    drawerStyling: {
      fontSize: "16px",
      margin: "30px 36px 4px 0px",
      paddingLeft: "30px",
    },
  };
};

const NavContent = ({ classes, theme, handleDrawer, isDrawer }) => {
  const location = useLocation();

  const homePath = "/";
  const registrationPath = "/registration";
  const sponsorshipPath = "/sponsorships";

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Link
        to={homePath}
        onClick={() => {
          handleDrawer(false);
        }}
        className={`${isDrawer && classes.drawerStyling} ${classes.link} ${
          location.pathname === homePath
            ? isDrawer
              ? classes.activeDrawerLink
              : classes.activeLink
            : classes.inactiveLink
        }`}
      >
        HOME
      </Link>
      <Link
        to={registrationPath}
        onClick={() => {
          handleDrawer(false);
        }}
        className={`${isDrawer && classes.drawerStyling} ${classes.link} ${
          location.pathname === registrationPath
            ? isDrawer
              ? classes.activeDrawerLink
              : classes.activeLink
            : classes.inactiveLink
        }`}
      >
        REGISTRATION
      </Link>
      <Link
        to={sponsorshipPath}
        onClick={() => {
          handleDrawer(false);
        }}
        className={`${isDrawer && classes.drawerStyling} ${classes.link} ${
          location.pathname === sponsorshipPath
            ? isDrawer
              ? classes.activeDrawerLink
              : classes.activeLink
            : classes.inactiveLink
        }`}
      >
        SPONSORSHIPS
      </Link>
      <a
        href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=9F6DPWKQ4Q9Q2"
        target="_blank"
        rel="noreferrer"
        className={`${isDrawer && classes.drawerStyling} ${classes.link} ${
          classes.inactiveLink
        }`}
        onClick={() => {
          handleDrawer(false);
        }}
      >
        DONATE
      </a>
      <a
        href="https://lovecradleint.org/"
        target="_blank"
        rel="noreferrer"
        className={`${isDrawer && classes.drawerStyling} ${classes.link} ${
          classes.inactiveLink
        }`}
        onClick={() => {
          handleDrawer(false);
        }}
      >
        LOVE CRADLE INTERNATIONAL
      </a>
    </>
  );
};

const Nav = ({ classes }) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawer = (state) => {
    setDrawerOpen(state);
  };

  return isDownSm ? (
    <Grid container justifyContent="flex-end">
      <Button
        onClick={() => {
          handleDrawer(true);
        }}
      >
        <MenuIcon style={{ fontSize: "30px" }} />
      </Button>
      <Drawer
        open={drawerOpen}
        onClose={() => {
          handleDrawer(false);
        }}
      >
        <NavContent
          classes={classes}
          theme={theme}
          handleDrawer={handleDrawer}
          isDrawer
        />
      </Drawer>
    </Grid>
  ) : (
    <MaxWidthContainer>
      <Grid className={classes.nav}>
        <NavContent
          classes={classes}
          theme={theme}
          handleDrawer={handleDrawer}
        />
      </Grid>
    </MaxWidthContainer>
  );
};

export default withStyles(styles, { withTheme: true })(Nav);
