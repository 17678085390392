//Material-ui/core
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#006408",
    },
    secondary: {
      main: "#E2D6D1",
      contrastText: "#000",
    },
    grey: {
      dark: "#707070",
      mid: "#999999",
      light: "#c3c3c3",
      veryLight: "#F4F4F4",
    },
    white: {
      main: "#fff",
    },
    black: {
      main: "#000",
    },
    text: {
      primary: "#000000",
      secondary: "#707070",
    },
    orange: "#F1B17C",
    blue: {
      main: "#27778D",
      light: "#8CBEDF",
    },
    green: {
      veryLight: "#E6F0E0",
      light: "#ACCA90",
      main: "#90B86B",
      dark: "#006408",
    },
    error: { main: "#D0021b" },
    warning: { main: "#F69C0F" },
    success: { main: "#90B86B" },
  },
  typography: {
    fontFamily: "Open sans, sans-serif",
    h1: {
      fontSize: "3.25rem", // 50px
      lineHeight: 1.3,
      fontWeight: 700,
      letterSpacing: 0.8,
    },
    h2: {
      fontSize: "2.75rem", // 44px
      lineHeight: 1.15,
      fontWeight: 700,
      paddingBottom: "2px",
      letterSpacing: 0.4,
    },
    h3: {
      fontSize: "1.625rem", // 26px
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 0.4,
    },
    h4: {
      fontSize: "1.5rem", // 24px
      fontWeight: 500,
      lineHeight: 1.3,
      paddingBottom: "2px",
      letterSpacing: 0.4,
    },
    h5: {
      fontSize: "1.25rem", // 20px
      lineHeight: 1.2,
      fontWeight: 400,
      paddingBottom: "2px",
      letterSpacing: 0.4,
    },
    h6: {
      fontSize: "1.125rem", // 18px
      lineHeight: 1.35,
      fontWeight: 400,
      paddingBottom: "2px",
      letterSpacing: 0.2,
    },
    // black body fonts
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: "0.9375rem",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: 0.15,
    },
    caption: {
      fontSize: "0.75rem",
      color: "#888",
      lineHeight: 1.2,
    },
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1500,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "26px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(0,0,0,0.5)",
        },
      },
    },
  },
});

export default theme;
