import React from "react";

// Mui
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  card: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "62px !important",
    },
  },
  cardTitle: {
    fontWeight: "700 !important",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 195px)",
    },
  },
});

const RegisterCard = ({ item, classes }) => {
  return (
    <Grid item xs={12}>
      <Card elevation={3} className={classes.card}>
        <Typography
          paragraph={item.description ? true : false}
          className={classes.cardTitle}
        >
          {item.name}&nbsp;&nbsp;
          {item.originalCost && (
            <>
              <Typography
                display="inline"
                color="textSecondary"
                style={{ textDecoration: "line-through" }}
              >
                ${item.originalCost}
              </Typography>
              &nbsp;&nbsp;
            </>
          )}
          <Typography
            display="inline"
            color="textSecondary"
            style={{ fontWeight: 700 }}
          >
            ${item.cost}
          </Typography>
        </Typography>

        {(item.description || item.secondaryMessage) && (
          <Grid style={{ marginTop: "8px" }}>
            <Typography gutterBottom={item.secondaryMessage ? true : false}>
              {item.description}
            </Typography>

            {item.secondaryMessage && (
              <Typography color="textSecondary" variant="body2">
                {item.secondaryMessage}
              </Typography>
            )}
          </Grid>
        )}

        {item.code && <Grid>{item.code}</Grid>}
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(RegisterCard);
