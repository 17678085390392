import React from "react";
import { Link } from "react-router-dom";
// import styled from "styled-components";

// Mui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { withStyles, useTheme } from "@mui/styles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// App
import PageTemplate, { MaxWidthContainer } from "components/pageTemplate";
import RegisterCard from "components/registerCard";
// import theme from "app/theme";

// const MasterSponsorImg = styled.img`
//   height: 60px;
//   padding: 4px 10px;
//   ${theme.breakpoints.up("md")} {
//     height: 90px;
//     padding: 7px 15px;
//   }
//   ${theme.breakpoints.up("lg")} {
//     width: 250px;
//     height: auto;
//     padding: 10px 20px;
//   }
// `;

const styles = (theme) => ({
  sponsorshipsBg: {
    backgroundColor: theme.palette.green.light,
    marginTop: "-77px",
  },
  contentContainer: {
    flexWrap: "wrap !important",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap !important",
    },
  },

  missionGrid: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: "-30px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "420px",
      minWidth: "420px",
      marginTop: "-112px !important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
      minWidth: "500px",
      marginRight: "0px !important",
    },
  },
  missionCard: {
    backgroundColor: `${theme.palette.green.veryLight} !important`,
    marginBottom: "30px",
    padding: "38px 45px !important",
    borderTopRightRadius: "4px !important",
    borderBottomRightRadius: "4px !important",
    [theme.breakpoints.up("md")]: {
      borderTopRightRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    },
    [theme.breakpoints.up("lg")]: {
      borderTopRightRadius: "4px !important",
      borderBottomRightRadius: "4px !important",
    },
  },
  card: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "62px !important",
    },
  },
  cardTitle: {
    fontWeight: "700 !important",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 195px) !important",
    },
  },
});

const SponsorInfo = ({ form, setForm, fieldGroupStyle, classes }) => {
  return (
    <form
      target="paypal"
      action="https://www.paypal.com/cgi-bin/webscr"
      method="post"
    >
      <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
      <input
        class="input-fill"
        type="hidden"
        name="hosted_button_id"
        value="N73L69KUJM5J2"
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: 700 }}
            gutterBottom
            className={classes.cardTitle}
          >
            Please save/add this information to your PayPal cart so we have your
            contact information linked to the sponsorship.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <input
            class="input-fill"
            type="hidden"
            name="on0"
            value="First name"
          />
          <Typography variant="body2">First name *</Typography>
          <input
            class="input-fill"
            type="text"
            id="os0"
            name="os0"
            maxlength="200"
            required
            value={form.firstName}
            onChange={(e) => {
              setForm({ ...form, firstName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <input
            class="input-fill"
            type="hidden"
            name="on1"
            value="Last name"
          />
          <Typography variant="body2">Last name *</Typography>
          <input
            class="input-fill"
            type="text"
            id="os1"
            name="os1"
            maxlength="200"
            required
            value={form.lastName}
            onChange={(e) => {
              setForm({ ...form, lastName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={4}>
          <input
            class="input-fill"
            type="hidden"
            name="on2"
            value="Company name"
          />
          <Typography variant="body2">Company name</Typography>
          <input
            class="input-fill"
            type="text"
            id="os2"
            name="os2"
            maxlength="200"
            value={form.companyName}
            onChange={(e) => {
              setForm({ ...form, companyName: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <input class="input-fill" type="hidden" name="on3" value="Phone" />
          <Typography variant="body2">Phone *</Typography>
          <input
            class="input-fill"
            type="text"
            id="os3"
            name="os3"
            maxlength="200"
            required
            value={form.phone}
            onChange={(e) => {
              setForm({ ...form, phone: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <input class="input-fill" type="hidden" name="on4" value="Email" />
          <Typography variant="body2">Email *</Typography>
          <input
            class="input-fill"
            type="text"
            id="os4"
            name="os4"
            maxlength="200"
            required
            value={form.email}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={12}>
          <input class="input-fill" type="hidden" name="on5" value="Address" />
          <Typography variant="body2">Postal address *</Typography>
          <input
            class="input-fill"
            type="text"
            id="os5"
            name="os5"
            maxlength="200"
            required
            value={form.address}
            onChange={(e) => {
              setForm({ ...form, address: e.target.value });
            }}
          />
        </Grid>
      </Grid>

      <div class="addToCartDiv">
        <input
          type="image"
          src={"./buttons/saveSponsorInfo.png"}
          border="0"
          name="submit"
          alt="PayPal"
        />
      </div>
    </form>
  );
};

// const ThirdPageAd = ({ fieldGroupStyle }) => (
//   <form
//     target="paypal"
//     action="https://www.paypal.com/cgi-bin/webscr"
//     method="post"
//   >
//     <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
//     <input
//       class="input-fill"
//       type="hidden"
//       name="hosted_button_id"
//       value="34CQCM3DWE6L8"
//     />
//     <div class="addToCartDiv">
//       <input
//         type="image"
//         src={"./buttons/addToCart.png"}
//         border="0"
//         name="submit"
//         alt="PayPal"
//       />
//     </div>
//   </form>
// );

// const HalfPageAd = ({ fieldGroupStyle }) => (
//   <form
//     target="paypal"
//     action="https://www.paypal.com/cgi-bin/webscr"
//     method="post"
//   >
//     <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
//     <input
//       class="input-fill"
//       type="hidden"
//       name="hosted_button_id"
//       value="AWAWT8JGCR39J"
//     />
//     <div class="addToCartDiv">
//       <input
//         type="image"
//         src={"./buttons/addToCart.png"}
//         border="0"
//         name="submit"
//         alt="PayPal"
//       />
//     </div>
//   </form>
// );

// const Gallery = ({ fieldGroupStyle }) => (
//   <form
//     target="paypal"
//     action="https://www.paypal.com/cgi-bin/webscr"
//     method="post"
//   >
//     <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
//     <input
//       class="input-fill"
//       type="hidden"
//       name="hosted_button_id"
//       value="RCQ4TT7HKBR2S"
//     />

//     <div class="addToCartDiv">
//       <input
//         type="image"
//         src={"./buttons/addToCart.png"}
//         border="0"
//         name="submit"
//         alt="PayPal"
//       />
//     </div>
//   </form>
// );

const Hole = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="KMQK95PA99BNY"
    />

    <Grid container spacing={2} style={fieldGroupStyle}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <input
          class="input-fill"
          type="hidden"
          name="on0"
          value="Sign quantity"
        />
        <Grid sx={{ display: "none" }}>
          <Typography variant="body2">Sign quantity *</Typography>
          <select name="os0">
            <option value="1 sign">1 sign - $150</option>
            <option value="2 signs">2 signs - $200</option>
            <option value="3 signs">3 signs - $250</option>
            <option value="4 signs">4 signs - $300</option>
            <option value="5 signs">5 signs - $350</option>
            <option value="6 signs">6 signs - $400</option>
            <option value="7 signs">7 signs - $450</option>
            <option value="8 signs">8 signs - $500</option>
            <option value="9 signs">9 signs - $550</option>
            <option value="10 signs">10 signs - $600</option>
          </select>
        </Grid>
        <input
          class="input-fill"
          type="hidden"
          name="currency_code"
          value="USD"
        />
      </Grid>
    </Grid>

    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const PuttingSign = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="PCX4TEL9JDL3A"
    />
    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const Birdie = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="8D4YR6VZ7PEL8"
    />
    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const Eagle = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="77MBQ5PUL378Y"
    />

    <Typography variant="body2" class="bold fieldGroupTitle">
      Golfer 1
    </Typography>
    <Grid container spacing={2} style={fieldGroupStyle}>
      <Grid item xs={12} sm={6} md={4}>
        <input class="input-fill" type="hidden" name="on0" value="Full name" />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os0"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input class="input-fill" type="hidden" name="on2" value="Phone" />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input class="input-fill" type="hidden" name="on3" value="Email" />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>
    </Grid>
    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const DoubleEagle = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="H9KMXKYKL7CK6"
    />

    <Grid container spacing={2} style={fieldGroupStyle}>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 1
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on0"
          value="Golfer 1 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os0"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on2"
          value="Golfer 1 phone"
        />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on3"
          value="Golfer 1 email"
        />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 2
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on4"
          value="Golfer 2 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os4"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on6"
          value="Golfer 2 phone"
        />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os6"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on7"
          value="Golfer 2 email"
        />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os7"
          maxlength="200"
          required
        />
      </Grid>
    </Grid>
    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const HoleInOne = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="Y7Y4E8BVSXCYY"
    />

    <Grid container spacing={2} style={fieldGroupStyle}>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 1
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on1"
          value="Golfer 1 full name"
          required
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os1"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on2"
          value="Golfer 1 phone"
          required
        />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on3"
          value="Golfer 1 email"
          required
        />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 2
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on4"
          value="Golfer 2 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os4"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on5"
          value="Golfer 2 phone"
        />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os5"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on6"
          value="Golfer 2 email"
        />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os6"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 3
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on7"
          value="Golfer 3 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os7"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on8"
          value="Golfer 3 phone"
        />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os8"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <input
          class="input-fill"
          type="hidden"
          name="on9"
          value="Golfer 3 email"
        />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os9"
          maxlength="200"
          required
        />
      </Grid>
    </Grid>

    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const Master = ({ fieldGroupStyle }) => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input class="input-fill" type="hidden" name="cmd" value="_s-xclick" />
    <input
      class="input-fill"
      type="hidden"
      name="hosted_button_id"
      value="5E7SQ2V2MT5VE"
    />

    <Grid container spacing={2} style={fieldGroupStyle}>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 1
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on0"
          value="Golfer 1 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os0"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on1"
          value="Golfer 1 phone"
        />
        <Typography variant="body2">
          Contact information (phone & email) *
        </Typography>
        <input
          class="input-fill"
          type="text"
          name="os1"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 2
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on2"
          value="Golfer 2 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on3"
          value="Golfer 2 phone"
        />
        <Typography variant="body2">
          Contact information (phone & email) *
        </Typography>
        <input
          class="input-fill"
          type="text"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 3
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on4"
          value="Golfer 3 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os4"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on5"
          value="Golfer 3 phone"
        />
        <Typography variant="body2">
          Contact information (phone & email) *
        </Typography>
        <input
          class="input-fill"
          type="text"
          name="os5"
          maxlength="200"
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" class="bold fieldGroupTitle">
          Golfer 4
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on6"
          value="Golfer 4 full name"
        />
        <Typography variant="body2">Full name (first & last) *</Typography>
        <input
          class="input-fill"
          type="text"
          name="os6"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          class="input-fill"
          type="hidden"
          name="on7"
          value="Golfer 4 phone"
        />
        <Typography variant="body2">
          Contact information (phone & email) *
        </Typography>
        <input
          class="input-fill"
          type="text"
          name="os7"
          maxlength="200"
          required
        />
      </Grid>
    </Grid>

    <div class="addToCartDiv">
      <input
        type="image"
        src={"./buttons/addToCart.png"}
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const InlineButton = () => (
  <Button
    disableElevation
    component={Link}
    to="/registration"
    sx={{
      textTransform: "lowercase",
      margin: "-13px -2px -10px -8px",
    }}
  >
    <Typography variant="body2" sx={{ textDecoration: "underline" }}>
      Registration
    </Typography>
  </Button>
);

const contactInfoMessage = (
  <Grid sx={{ marginTop: "6px" }}>
    If you are registering golfers who are not included in this sponsorship, go
    to the <InlineButton />
    page.
  </Grid>
);
// "Please enter all registrant contact information before adding this sponsorship to your cart. ";

const coordinateMessage =
  "If sponsoring, a member of our team will reach out to you to coordinate.";

const Sponsor = ({ classes }) => {
  const theme = useTheme();

  const fieldGroupStyle = { marginTop: "-4px" };

  const [form, setForm] = React.useState({
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    email: "",
    address: "",
  });

  const sponsorships = [
    // {
    //   name: "1/3 PAGE PROGRAM ADVERTISEMENT",
    //   cost: "100",
    //   code: <ThirdPageAd fieldGroupStyle={fieldGroupStyle} />,
    // },
    // {
    //   name: "1/2 PAGE PROGRAM ADVERTISEMENT",
    //   cost: "200",
    //   code: <HalfPageAd fieldGroupStyle={fieldGroupStyle} />,
    // },
    // {
    //   name: "GALLERY",
    //   cost: "100",
    //   description: "Your company name appears in the event program.",
    //   code: <Gallery fieldGroupStyle={fieldGroupStyle} />,
    // },
    {
      name: "HOLE SPONSOR",
      cost: "150",
      description:
        "Company name/logo is prominently displayed on a 18” x 24” tee box or hole sign and on every golf cart.",
      code: <Hole fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "PUTTING GREEN SIGN",
      cost: "175",
      description:
        "Company name/logo is prominently displayed on a 24” x 36” putting green sign and on every golf cart.",
      code: <PuttingSign fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "BIRDIE",
      cost: "250",
      description:
        "Company name/logo appears on website, 10pt logo on banner, 1 hole sign, and on all golf carts.",
      code: <Birdie fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "EAGLE",
      cost: "500",
      description:
        "Company name/logo appears on website, 10 pt logo on banner, 2 hole signs, putting green sign, and on all golf carts. This sponsorship covers one golfer’s fee.",
      secondaryMessage: <>{contactInfoMessage}</>,
      code: <Eagle fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "DOUBLE EAGLE",
      cost: "1,000",
      description:
        "Company name/logo appears on website, 20 pt logo on banner, 2 hole signs, putting green sign, all golf carts, and is recognized at awards ceremony. This sponsorship covers two golfers’ fees.",
      secondaryMessage: <>{contactInfoMessage}</>,
      code: <DoubleEagle fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "HOLE IN ONE",
      cost: "2,000",
      description:
        "Company name/logo appears on website, 30 pt logo on banner, 3 hole signs, putting green sign, all golf carts, and is recognized at awards ceremony. This sponsorship covers four golfers’ fees.",
      secondaryMessage: <>{contactInfoMessage}</>,
      code: <HoleInOne fieldGroupStyle={fieldGroupStyle} />,
    },
    {
      name: "MASTER",
      cost: "4,000",
      description:
        "Company name/logo appears on website, 40 pt logo on banner, 4 hole signs, putting green sign, all golf carts, brochure, every golfer appreciation gift, and is recognized at awards ceremony. This sponsorship covers four golfers’ fees.",
      secondaryMessage: <>{contactInfoMessage}</>,
      code: <Master fieldGroupStyle={fieldGroupStyle} />,
    },
  ];

  return (
    <PageTemplate mainTitle="Sponsorships">
      <Grid className={classes.sponsorshipsBg}>
        <MaxWidthContainer>
          <Grid container spacing={3} className={classes.contentContainer}>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container spacing={2} style={{ marginBottom: "40px" }}>
                <Grid item xs={12}>
                  <Typography variant="body2" paragraph>
                    Fill out the forms below and pay on PayPal's website, or{" "}
                    <a
                      href="./Registration.pdf"
                      download="registration"
                      class="link"
                      onClick={() => {
                        console.log("hi");
                      }}
                    >
                      download the form
                    </a>{" "}
                    and mail it to us.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    When checking out, you can pay through PayPal by clicking
                    the yellow "PayPal" button or pay with a credit card by
                    clicking the gray "checkout" button.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    display="block"
                    variant="h3"
                    paragraph
                    textAlign="center"
                  >
                    SPONSOR INFORMATION
                  </Typography>
                  <Card
                    elevation={0}
                    className={classes.card}
                    style={{
                      position: "relative",
                      border: `2px solid ${theme.palette.blue.main}`,
                    }}
                  >
                    <SponsorInfo
                      form={form}
                      setForm={setForm}
                      fieldGroupStyle={fieldGroupStyle}
                      classes={classes}
                    />
                  </Card>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Typography
                    display="block"
                    variant="h3"
                    align="center"
                    gutterBottom
                  >
                    SPONSORSHIP LEVELS
                  </Typography>
                  <Typography display="block" align="center">
                    Sponsorship deadline: <b>Friday, September 13, 2024</b>
                  </Typography>
                  <Typography display="block" align="center">
                    {coordinateMessage}
                  </Typography>
                </Grid>

                {sponsorships.map((sponsorship) => (
                  <RegisterCard item={sponsorship} />
                ))}
              </Grid>
            </Grid>

            <Grid item className={classes.missionGrid}>
              <Card elevation={0} className={classes.missionCard}>
                <Typography variant="h6" paragraph style={{ fontWeight: 700 }}>
                  Each year over 100 million orphaned children around the world
                  struggle to make it.
                </Typography>
                <Grid
                  style={{
                    borderLeft: `3px solid ${theme.palette.blue.main}`,
                    paddingLeft: "18px",
                    marginBottom: "28px",
                  }}
                >
                  <Typography paragraph>40% go to prison</Typography>
                  <Typography paragraph>
                    40% develop lifelong mental disabilities
                  </Typography>
                  <Typography paragraph>
                    65%-70% of the girls will become manipulated into the
                    international sex trade or organized crime
                  </Typography>
                  <Typography paragraph>
                    70% of the boys will enter a life of crime
                  </Typography>
                  <Typography
                    paragraph
                    style={{ color: theme.palette.blue.main, fontWeight: 700 }}
                  >
                    Only 10% will adjust to life
                  </Typography>
                </Grid>

                <Typography>
                  Love Cradle International works to demonstrate to these
                  children the possibilities that exist outside the orphanage
                  walls. Our intervention both here in the U.S. and abroad, have
                  changed the lives of hundreds of these children through
                  educational services, transitional housing, improved living
                  conditions, hosting programs, advocacy, humanitarian help, and
                  adoption counseling and consulting.
                </Typography>

                <Grid style={{ margin: "38px 0px" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    endIcon={<ChevronRightIcon />}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=9F6DPWKQ4Q9Q2"
                  >
                    DONATE NOW
                  </Button>
                </Grid>

                <Typography style={{ fontWeight: 700 }}>
                  Thanks to you, some of our accomplishments in 2023 include:
                </Typography>
                <ul>
                  <li>
                    Love Cradle continues to lend a helping hand to displaced
                    refugee Ukrainian families that reach out to us for help.
                    This past year we have helped 10 refugee families (34
                    children) find a home in the US.
                  </li>
                  <li>
                    Summer Camps were held in the southern Odessa region (250
                    orphans, displaced and children from the village attended.
                    The focus of the camp was to children who had lost parents
                    in the war.)
                  </li>
                  <li>
                    Summer children&#39;s camps in the central area of Ukraine,
                    the Kirovograd State. (These camps ran from late spring to
                    late summer and were designed for children from all walks of
                    life...over 600 children ministered to)
                  </li>
                  <li>
                    Summer camp in the west region of Ukraine, Ivano Frankivsk
                    State, for village and displaced/refugee
                    children....approximately 50 children ministered to)
                  </li>
                  <li>
                    Three containers of humanitarian aid (approximate value over
                     $1,000,000) were shipped to Ukraine and distributed to
                    orphanages, refugee families and the elderly. Supplies was
                    also shipped to help with the summer camp programs this
                    summer 2024. (well over 3000 children/people cared for)
                  </li>
                  <li>
                    The ministry center in central Ukraine, Kirovograd State
                    continues to be a ministry jump off point to the central
                    area of Ukraine and also a humanitarian sort and
                    distribution center for the whole of Ukraine. The center
                    continues to host children/people in need and the gardens
                    and orchards continue to provide food for the needs of the
                    center and to children/people in need.
                  </li>
                  <li>
                    Love Cradle continues to maintain and support our Ukrainian
                    indigenous team/partners.
                  </li>
                </ul>

                <Divider style={{ margin: "50px 0px" }} />

                <Typography
                  align="center"
                  paragraph
                  style={{ fontWeight: 700 }}
                >
                  PASTORS MASTERS SPONSORS
                </Typography>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src="./sponsors/master/Hatfield.png"
                    alt="Hatfield Meats"
                    style={{
                      width: "200px",
                      margin: "10px 0px 30px",
                    }}
                  />
                  <img
                    src="./sponsors/master/PrestoTape.png"
                    alt="Presto Tape logo"
                    style={{
                      width: "220px",
                    }}
                  />
                  <img
                    src="./sponsors/master/Unique.png"
                    alt="Unique Wealth logo"
                    style={{
                      width: "220px",
                      margin: "20px 0px",
                    }}
                  />
                  <img
                    src="./sponsors/master/IHS.png"
                    alt="Industry Health Solutions"
                    style={{
                      width: "230px",
                      margin: "20px 0px",
                    }}
                  />
                  <img
                    src="./sponsors/master/Alderfer.png"
                    alt="Alderfer Glass"
                    style={{
                      width: "240px",
                      margin: "0px 0px 20px",
                    }}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>
    </PageTemplate>
  );
};

export default withStyles(styles)(Sponsor);
